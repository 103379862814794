<template>
  <div class="user-programs--history-table">
    <v-simple-table>
      <thead>
        <tr>
          <th>Program Name</th>
          <th>Intake Date</th>
          <th>Valid Until Date</th>
          <th>Plan</th>
          <th>Status</th>
          <th>Purchase Type</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="hasPrograms && !loading">
          <tr
            v-for="program in programs"
            :key="program.user_program.id"
            class="clickable"
            @click="goTo(program)"
          >
            <td style="width: 30%">
              <h2 class="subtitle-1">
                <v-icon
                  color="green lighten-2"
                  v-if="program.user_program.is_default"
                >
                  {{ icons.check }}
                </v-icon>
                {{ program.name }}
              </h2>
            </td>
            <td>
              {{ program.user_program.starts_at | formatIntakeDate($date) }}
            </td>
            <td>
              {{ program.user_program.valid_until | formatIntakeDate($date) }}
            </td>
            <td>
              <span v-if="program.package">
                {{ program.package.name }}
              </span>
            </td>
            <td>
              <v-chip
                v-if="program.user_program.deactivated_at"
                :color="
                  'deactivated'
                    | programStatusColor(program.user_program.starts_at)
                "
                dark
              >
                {{
                  program.user_program.is_deactivated
                    ? 'Deactivated'
                    : 'For Deactivation'
                }}
              </v-chip>
              <v-chip
                v-else
                :color="
                  program.user_program.status
                    | programStatusColor(program.user_program.starts_at)
                "
                text-color="white"
              >
                {{
                  program.user_program.status
                    | programStatus(program.user_program.starts_at)
                    | capitalize
                }}
              </v-chip>
            </td>
            <td>
              <v-chip
                :color="
                  program.user_program.checkout_info.purchase_type
                    | programPurchaseTypeColor
                "
                dark
              >
                <span class="text-capitalize">
                  {{
                    program.user_program.checkout_info.purchase_type
                      | programPurchaseType
                  }}
                </span>

                <span
                  class="font-weight-bold"
                  v-if="
                    program.user_program.checkout_info.purchase_type === 'trial'
                  "
                >
                  : Valid until
                  {{
                    program.user_program.checkout_info.purchase_valid_until
                      | formatDate('MMMM DD, YYYY')
                  }}
                </span>
              </v-chip>
            </td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="6" row />
    </v-simple-table>

    <no-list v-if="!hasPrograms && !loading" details="No Programs Enrolled" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import dayjs from 'dayjs'
import NoList from '@/components/elements/NoList'
import { mdiCheckCircle } from '@mdi/js'

export default {
  name: 'ProgramHistoryTable',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  data() {
    return {
      icons: {
        check: mdiCheckCircle,
      },
    }
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    programs: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  computed: {
    hasPrograms() {
      return !!this.programs.length
    },
  },

  filters: {
    programStatusColor(status, startsAt) {
      if (!startsAt && status !== 'deactivated') {
        return 'orange'
      }

      if (status === 'active') return 'primary'
      if (status === 'completed') return 'green'
      if (status === 'deactivated') return 'red'

      return 'orange'
    },

    programPurchaseTypeColor(type) {
      if (type === 'iap') return 'blue'
      if (type === 'trial') return 'grey'
      if (type === 'shopify') return 'primary'

      return 'teal' // For manual assignment
    },

    programPurchaseType(type) {
      if (type === 'iap') return 'In App'

      return type
    },

    formatIntakeDate(value, date) {
      if (!value) {
        return ''
      }

      return dayjs.utc(value).format('MMM DD, YYYY')
    },
  },

  methods: {
    goTo(program) {
      this.$emit('goTo', program.user_program.id)
    },
  },
}
</script>
