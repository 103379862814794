<template>
  <div class="user-profile--programs-list">
    <v-row>
      <v-col cols="12" sm="12" md="10" class="d-flex">
        <v-btn
          class="bg-primary-gradient primary ml-auto"
          @click="addNewPrgoram = true"
        >
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Assign New Program
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10">
        <program-history-table
          :programs="programs"
          :loading="loading"
          @goTo="goTo"
        />
      </v-col>
    </v-row>

    <assign-new-program v-model="addNewPrgoram" @refresh-list="fetchPrograms" />
  </div>
</template>

<script>
import AssignNewProgram from '@/components/modals/users/AssignNewProgram'
import ProgramHistoryTable from '../components/ProgramHistoryTable'

import { mdiPlus } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'UserProgramList',

  components: {
    AssignNewProgram,
    ProgramHistoryTable,
  },

  data() {
    return {
      loading: false,
      addNewPrgoram: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  created() {
    this.fetchPrograms()
  },

  destroyed() {
    this.clearPrograms()
  },

  computed: {
    ...mapState({
      programs: (state) => state.userProfile.programs,
    }),
  },

  methods: {
    ...mapActions({ getPrograms: 'userProfile/getPrograms' }),
    ...mapMutations({ clearPrograms: 'userProfile/clearPrograms' }),

    async fetchPrograms() {
      this.loading = true

      await this.getPrograms(this.$route.params.id)

      await this.$sleep()

      this.loading = false
    },

    goTo(programId) {
      this.$router.push({
        name: 'user.program-history.details',
        params: { programId },
      })
    },
  },
}
</script>
